table.bt thead, table.bt tbody th {
  display: none;
}

table.bt tfoot th, table.bt tfoot td, table.bt tbody td {
  vertical-align: top;
  float: left\9 ;
  width: 100%\9 ;
  border: 0;
  display: flex;
}

table.bt tfoot th:before, table.bt tfoot td:before, table.bt tbody td:before {
  content: attr(data-th) ": ";
  -ms-flex-shrink: 0;
  width: 6.5em;
  flex-shrink: 0;
  font-weight: bold;
  display: inline-block;
}

table.bt tfoot th.bt-hide, table.bt tfoot td.bt-hide, table.bt tbody td.bt-hide {
  display: none;
}

table.bt tfoot th .bt-content, table.bt tfoot td .bt-content, table.bt tbody td .bt-content {
  vertical-align: top;
}

.bt-wrapper.active {
  max-height: 310px;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

table.bt.bt--no-header tfoot td:before, table.bt.bt--no-header tbody td:before {
  display: none;
}

/*# sourceMappingURL=index.c0b09ed9.css.map */
